import { SpeedInsights } from '@vercel/speed-insights/react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Sidebar from './components/sidebar';
import Hero from './components/hero';

function App() {
  return (
    <Router>
      <Sidebar />
      <Hero />
    </Router>
  );
}

export default App;
