import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 50px;
  text-decoration: none;
  

  &:hover {
    background: #39375D;
    border-left: 4px solid #524CE4;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  font-size: 16px;
`;

const SidebarLabel2 = styled.span`
  margin-left: 11px;
`;

const DropdownLink = styled(Link)`
  background: #43406D;
  height: 50px;
  padding-left: 2.6rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 16px;

  &:hover {
    background: #524CE4;
    cursor: pointer;
  }
`;



const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
    
      <SidebarLink className='divider' to={item.path} onClick={item.subNav && showSubnav}>
        <div className='side-icon'>
        
        {item.icon} <SidebarLabel> {item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink className='' to={item.path} key={index}>
              {item.icon}
              <SidebarLabel2>{item.title}</SidebarLabel2>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
