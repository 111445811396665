import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import { Divide as Hamburger } from 'hamburger-react'
import SubMenu from './subMenu';
import { IconContext } from 'react-icons/lib';

const Nav = styled.div`
  background: #262443;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 1.3rem;
  font-size: 1.5rem;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #262443;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50px;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 650ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const NavTitle = styled.h2`
  color: #FFFFFF;
  font-size: 22px;
  font-family: 'Inter-B';
`

const SidebarData = [
  {
    title: 'Overview',
    path: '/overview',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Users',
        path: '/overview/users',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Revenue',
        path: '/overview/revenue',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'Products',
    path: '/products',
    icon: <FaIcons.FaCartPlus />
  },

];

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  return (
    <>
      <nav>
        <Nav>
          <NavIcon to='#'>
          <Hamburger toggled={sidebar} toggle={setSidebar} color="#FFF" size={25} />
          </NavIcon>
          <div className='ps-5'>
            <NavTitle>RNothing</NavTitle>
          </div>
          
        </Nav>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </nav>
    </>
  );
};

export default Sidebar;
